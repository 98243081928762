import React from 'react';

import { PhotoHeader } from '@lerna-monorepo/common/components/photoHeader/photoHeader.component';
import { PromoTextContent } from '@lerna-monorepo/common/components/promoTextContent/promoTextContent.component';
import { ImagesSectionContent } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.component';
import { PhotoGrid } from '@lerna-monorepo/common/components/photoGrid/photoGrid.component';
import { ImagesSectionContentLayoutType } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.enums';
import { ImagesSectionContentData } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.types';
import { TestimonialsSlider } from '@lerna-monorepo/common/components/testimonialsSlider/testimonialsSlider.component';
import { renderWhenTrue, getBaseUrlByCategory, getLanguagePrefix } from '@lerna-monorepo/common/utils';
import { useScrollToSection } from '@lerna-monorepo/common/utils/useScrollToSection';
import { useImageSectionAnimation } from '@lerna-monorepo/common/utils/useImageSectionAnimation';
import { Color } from '@lerna-monorepo/common/theme';

import {
  CaseStudyLinkSectionContainer,
  ImagesSectionContentContainer,
  LocalCaseStudySection,
  LocalLinkSection,
  HalfBox,
  PhotoGridContainer,
} from './homepage.styled';
import { HomepageData } from './homepage.types';

const IndexPage: React.FC<HomepageData> = (pageData: HomepageData) => {
  const { data, testimonials, currentLanguage, newsBasePath, caseStudyBasePath, basePaths } = pageData.pageContext;
  const {
    headerContent,
    promoTextContent,
    imagesSectionContent,
    photoGrid,
    caseStudySection,
    linkSection,
  } = data;
  const { handleScroll, sectionRef } = useScrollToSection();
  const { imageSectionRefs, setRefresh } = useImageSectionAnimation();
  const renderImagesSectionContent = renderWhenTrue(() =>
    imagesSectionContent.map(
      (imageSection: ImagesSectionContentData, index: number) => (
        <ImagesSectionContent
          key={imageSection.headerContent}
          buttonLabel={imageSection.buttonLabel}
          image={imageSection.image}
          icon={imageSection.icon}
          headerContent={imageSection.headerContent}
          positionType={imageSection.positionType}
          internalPage={imageSection.internalPage}
          externalPage={imageSection.externalPage}
          currentLanguage={currentLanguage}
          layoutType={
            index % 2 === 0
              ? ImagesSectionContentLayoutType.ImageContentLayout
              : ImagesSectionContentLayoutType.ContentImageLayout
          }
          imageSectionRefs={imageSectionRefs}
          setRefresh={setRefresh}
        />
      )
    )
  );

  return (
    <>
      <PhotoHeader
        subheaderContent={headerContent.subheaderContent}
        headerContent={headerContent.headerContent}
        buttonLabel={headerContent.buttonLabel}
        background={headerContent.background}
        videoBackground={headerContent.videoBackground}
        handleScroll={handleScroll}
      />
      <PromoTextContent
        headerContent={promoTextContent.headerContent}
        description={promoTextContent.description}
        promotionImage={promoTextContent.promotionImage}
      />
      <ImagesSectionContentContainer>
        {renderImagesSectionContent(!!imagesSectionContent)}
      </ImagesSectionContentContainer>
      <PhotoGridContainer ref={sectionRef}>
        <PhotoGrid items={photoGrid} />
      </PhotoGridContainer>
      <CaseStudyLinkSectionContainer>
        <LocalCaseStudySection
          header={caseStudySection.header}
          caseStudyBoxes={caseStudySection.caseStudyBoxes}
          boxesBaseUrl={caseStudyBasePath}
          currentLanguage={currentLanguage}
          basePaths={basePaths}
        />
        <LocalLinkSection
          header={linkSection.header}
          linkBoxes={linkSection.linkBoxes}
          boxesBaseUrl={`${process.env.MAIN_URL}${getLanguagePrefix(currentLanguage)}${newsBasePath}`}
        />
        <HalfBox />
      </CaseStudyLinkSectionContainer>
      <TestimonialsSlider
        backgroundColor={Color.white}
        circlesBackgroundColor={Color.secondary}
        testimonials={testimonials}
        currentLanguage={currentLanguage}
      />
    </>
  );
};

export default IndexPage;
