import styled from 'styled-components';

import { Color } from '@lerna-monorepo/common/theme';
import { CaseStudySection } from '@lerna-monorepo/common/components/caseStudySection/caseStudySection.component';
import { LinkSection } from '@lerna-monorepo/common/components/linkSection/linkSection.component';

export const LocalCaseStudySection = styled(CaseStudySection)`
  padding-top: 150px;
`;

export const LocalLinkSection = styled(LinkSection)`
  padding-top: 100px;
  padding-bottom: 150px;
`;

export const CaseStudyLinkSectionContainer = styled.div`
  background-color: ${Color.secondary};
  position: relative;
`;

export const ImagesSectionContentContainer = styled.div`
  margin: -45px 0 100px;
`;

export const HalfBox = styled.div`
  width: 50%;
  background-color: ${Color.white};
  height: 45px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const PhotoGridContainer = styled.div``;
